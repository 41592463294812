export interface SignUpBody {
  mail: string;
  name: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  passwordConfirm: string;
  birthday: string;
}

export interface UserRegisterParams {
  email: string;
  password: string;
  displayName: string;
  companyName: string;
  websiteUrl?: string;
}
export interface UserLoginParams {
  email: string;
  password: string;
}

export enum AUTH_MODE_TYPE {
  GUEST = "guest",
  USER = "user",
  ADMIN_WORK_AS_USER = "admin_work_as_user",
  WORKSPACE = "workspace",
  ADMIN_WORK_AS_WORKSPACE = "admin_work_as_workspace",
  ADMIN = "admin",
  ADMIN_OWNER = "admin_ws",
}

export enum WORKSPACE_TYPE {
  FOUNDER = "founder",
  INVESTOR = "investor",
  PORTFOLIO = "portfolio",
  ADMIN = "admin",
}
