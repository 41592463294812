import { PayloadAction } from "@reduxjs/toolkit";
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import {
  AUTH_MODE,
  USER_TOKEN,
  WORKSPACE_TYPE_KEY,
  WORKSPACE_UPDATE,
} from "constants/app.const";
import { AUTH_MODE_TYPE, WORKSPACE_TYPE } from "models/auth.model";
import { LocalStorage } from "utils/localStorage.util";

export interface AuthState {
  loading: boolean;
  mode: AUTH_MODE_TYPE;
  workspaceType?: WORKSPACE_TYPE;
  workspaceUpdate?: boolean;
  userToken: string | undefined;
}

export const initialState: AuthState = {
  loading: false,
  mode: LocalStorage.get(AUTH_MODE) || AUTH_MODE_TYPE.GUEST,
  workspaceType: LocalStorage.get(WORKSPACE_TYPE_KEY) || undefined,
  workspaceUpdate: LocalStorage.get(WORKSPACE_UPDATE) || undefined,
  userToken: LocalStorage.get(USER_TOKEN) || undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userUpdateRole(_, _action: PayloadAction<string>) {},
    userLogin(_, _action: PayloadAction<string>) {},
    userLoginSuccess(state, _action: PayloadAction<AUTH_MODE_TYPE>) {
      state.mode = _action.payload;
    },
    userLoginFailed(state, _action: PayloadAction) {
      state.mode = AUTH_MODE_TYPE.GUEST;
    },
    userLogout() {},
    userLogoutSuccess(state) {
      state.mode = AUTH_MODE_TYPE.GUEST;
    },
    userUpdateToken(_, _action: PayloadAction<string | undefined>) {},
    userUpdateTokenSuccess(state, _action: PayloadAction<string | undefined>) {
      state.userToken = _action.payload;
    },
    workspaceLogin(
      state,
      _action: PayloadAction<{
        id: string;
        type?: WORKSPACE_TYPE;
        isUpdate?: boolean;
        callback: () => void;
      }>
    ) {
      state.loading = true;
    },
    workspaceLoginSuccess(
      state,
      action: PayloadAction<{
        type?: WORKSPACE_TYPE;
        isUpdate?: boolean;
      }>
    ) {
      state.workspaceType = action.payload.type;
      state.workspaceUpdate = action.payload.isUpdate;
      if (state.mode === AUTH_MODE_TYPE.ADMIN_WORK_AS_USER) {
        state.mode = AUTH_MODE_TYPE.ADMIN_WORK_AS_WORKSPACE;
      } else {
        state.mode = AUTH_MODE_TYPE.WORKSPACE;
      }
      state.loading = false;
    },
    workspaceLoginFailed(state) {
      state.loading = false;
    },
    workspaceLogout() {},
    workspaceLogoutSuccess(state) {
      if (
        [
          AUTH_MODE_TYPE.ADMIN_WORK_AS_WORKSPACE,
          AUTH_MODE_TYPE.ADMIN_OWNER,
        ].includes(state.mode)
      ) {
        state.mode = AUTH_MODE_TYPE.ADMIN_WORK_AS_USER;
      } else {
        state.mode = AUTH_MODE_TYPE.USER;
      }
      state.workspaceType = undefined;
      state.workspaceUpdate = undefined;
    },
  },
});
export const {
  actions: authActions,
  reducer: authReducer,
  name: sliceKey,
} = authSlice;
